import { useEffect, useMemo, useState } from 'react'

import { useReactiveVar } from '@apollo/client'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'

import PageLoader from '~/components/ui/PageLoader'
import { LUMOSITY_USER_COOKIE } from '~/constants'
import { ErrorEventTypes } from '~/events/eventTypes'
import useTrackEvents from '~/events/trackers/useTrackEvents'
import { useLogout } from '~/hooks/useAuth'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import { currentUserVar } from '~/reactive-vars'
import logger from '~/utils/logger'
import { getBrowserCookieOpts, parseUser } from '~/utils/loginUtils'

const { publicRuntimeConfig } = getConfig()
const LoginTokenApiUrl = `${publicRuntimeConfig.authMiddleware.endpoint}/login-with-token`
const LoginTokenQueryParam = 'login_token'

interface LoginTokenExchange {
  sessionJWT: string
}
const exchangeLoginTokenToSessionJWT = async ({
  loginToken,
}: {
  loginToken: string
}): Promise<LoginTokenExchange | undefined> => {
  logger.info('exchangeLoginTokenToSessionJWT: Process started')
  const response = await fetch(LoginTokenApiUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', authorization: loginToken },
    credentials: 'include',
    cache: 'no-store',
    body: '{}',
  })
  try {
    const resp = await response.json()
    logger.info('exchangeLoginTokenToSessionJWT: final data', resp)
    return { sessionJWT: resp.jwt }
  } catch (err) {
    logger.info('exchangeLoginTokenToSessionJWT: error', err)
    throw err
  }
}

interface RouteGuardWrapperProps {
  children: JSX.Element
}

const LoginTokenToSession = ({ children }: RouteGuardWrapperProps): JSX.Element => {
  const [, setCookie] = useCookies([LUMOSITY_USER_COOKIE])
  const currentUser = useReactiveVar(currentUserVar)
  const router = useRouter()
  const { query } = router
  const queryParams = useMemo(() => new URLSearchParams(query as Record<string, string>), [query])
  const { trackErrorEvent } = useTrackEvents()

  const t = useTranslationForNamespace('signin')
  const UnknownErrorMessage = useMemo(() => t('unknownSigninErrorMessage'), [t])

  const { processOryLogout } = useLogout()

  const loginToken = queryParams.get(LoginTokenQueryParam)

  const [{ pageLoading, pageError }, setPageState] = useState<{
    pageLoading: boolean
    pageError: string | null
  }>({
    // we are making sure that until this process completes, don't render any children
    // make sure pageLoading becomes false once process is completed, & never make it true
    // this will avoid unmounting & mounting of all nested components
    pageLoading: true,
    pageError: null,
  })

  const [isApiFetching, setIsApiFetching] = useState<boolean>(false)

  useEffect(() => {
    // if no login token, then we don't need to wait
    if (!loginToken) {
      setPageState({ pageLoading: false, pageError: null })
      return
    }

    // if not waiting, then don't do anything, this is terminal state
    if (!pageLoading) return

    // if we are already fetching, then don't start another process
    if (isApiFetching) return

    const handleLoginToken = async () => {
      try {
        setIsApiFetching(true)
        // exchange login token to session JWT
        const loginSessionJWT = await exchangeLoginTokenToSessionJWT({ loginToken })
        const sessionJWT = loginSessionJWT?.sessionJWT
        const tokenUser = parseUser(sessionJWT)

        // if we are not able to extract user from loginSessionJWT, then we can't proceed
        if (!tokenUser) {
          logger.info(`not able to extract user from loginToken ${loginToken} with having tokenUser:`, tokenUser)
          throw new Error('Error while exchanging login token to session JWT')
        }

        // if there is existing session of user & user is different, forceLogout old user & login new user
        if (currentUser && currentUser.id !== tokenUser.id) {
          // start logout process of old user
          await processOryLogout()
        }

        // setting this cookie should also trigger the reactive var to update the user from usePersistedClientValues,
        // but until that happens, nested components might be using the old value,
        // to prevent that we are setting the currentUserVar var here as well
        setCookie(LUMOSITY_USER_COOKIE, sessionJWT, getBrowserCookieOpts())
        currentUserVar(tokenUser)
        setPageState({ pageLoading: false, pageError: null })

        // we know we have ended the process, so we can safely remove login_token from query params
        // this has to be last step, because no presence of login_token in url will render the children, as pageLoading will be false
        // we only want to render children when whole process is completed
        queryParams.delete(LoginTokenQueryParam)
        await router.replace({ pathname: router.pathname, query: queryParams.toString() })
      } catch (error) {
        trackErrorEvent({
          error_type: ErrorEventTypes.AccessTokenToJwtExchangeFail,
          error_location: 'LoginTokenToSession::handleLoginToken::catch',
          error_message: UnknownErrorMessage,
          error_details: error,
        })
        logger.error(error)
        setPageState({ pageLoading: false, pageError: UnknownErrorMessage })
      } finally {
        setIsApiFetching(false)
      }
    }
    handleLoginToken()
  }, [
    isApiFetching,
    currentUser,
    setPageState,
    loginToken,
    processOryLogout,
    queryParams,
    router,
    setCookie,
    pageLoading,
    UnknownErrorMessage,
    trackErrorEvent,
  ])

  if (pageLoading) return <PageLoader />
  if (pageError) return <div>{UnknownErrorMessage}</div>

  return children
}

export default LoginTokenToSession
