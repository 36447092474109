/* eslint-disable no-console */
import _ from 'lodash'

import { logToRollbar } from '~/utils/errorUtils'

/**
 * Possible values of the CONSOLE_LOG_LEVEL environment variable.
 *
 * Order matters: higher-indexed log levels include all logs below them.
 * NB: These are also used as method names on `console`
 */
const LOG_LEVELS = ['debug', 'info', 'warn', 'error'] as const

type LogLevel = typeof LOG_LEVELS[number]

const isProduction = () => process.env.NODE_ENV?.toLowerCase() === 'production'

const getCurrentLogLevelIndex = () => {
  const windowLogLevel = LOG_LEVELS.indexOf((globalThis as any).LOG_LEVEL)
  if (windowLogLevel >= 0) {
    return windowLogLevel
  }

  const envLogLevel = LOG_LEVELS.indexOf(process.env.CONSOLE_LOG_LEVEL as LogLevel)
  if (envLogLevel >= 0) {
    return envLogLevel
  }

  // if no log_level is set, default to info in production and debug otherwise
  if (isProduction()) {
    return LOG_LEVELS.indexOf('info')
  }

  return LOG_LEVELS.indexOf('debug')
}

const logAtLevel: (level: LogLevel) => typeof console.log =
  (level) =>
  (...args: any[]) => {
    if (LOG_LEVELS.indexOf(level) < getCurrentLogLevelIndex()) {
      return
    }

    console[level](...args)
  }

/**
 * Use for client-side errors that we definitely do not need reported to rollbar (e.g. noNetwork)
 */
const clientError: typeof console.error = (...args: any[]) => {
  console.error(...args)
}

const error: typeof console.error = (...args: any[]) => {
  console.error(...args)

  if (isProduction()) {
    // callers to logging functions are pretty free with their argument assembly, so let's try to sort things out a little:
    // - if we don't have an exception from the caller, make one so we at least get a stack to the log
    // - we have a `error('%c%s', style, prefix, message)` pattern for styled messages; throw away the first three
    // - need to consolidate down to one message, just concat them all

    let err: Error | null = null
    const objectArgs = []
    const messageParts = []

    if (args[0] === '%c%s') {
      // chop off `'%c%s', style, prefix`
      args = args.slice(3)
    }

    for (const arg of args) {
      try {
        // print all args as stringified JSON, if any of the arg is instanceof Error,
        // rollbar only logs the error message not the granular details
        // rollbar doesn't log console.dir or console.group
        // wrap under try catch because some objects can't be stringified
        console.log('logger::error::print', JSON.stringify(arg))
      } catch (e) {
        console.error('logger::error::catch', e, arg)
      }

      if (arg instanceof Error) {
        err = arg
      } else if (_.isString(arg) || !_.isObject(arg)) {
        messageParts.push(arg)
      } else {
        // objects go straight to rollbar in the order they were logged
        objectArgs.push(arg)
      }
    }

    if (!err) {
      try {
        // do some browsers still need it to be thrown to fill in the stack?
        throw new Error('error logged')
      } catch (e) {
        err = e as Error
      }
    }

    if (!messageParts.length) {
      messageParts.push(err.message)
    }

    logToRollbar(err, messageParts.join(''), ...objectArgs)
  }
}

const logger = {
  clientError,
  error,
  warn: logAtLevel('warn'),
  info: logAtLevel('info'),
  log: logAtLevel('info'),
  debug: logAtLevel('debug'),
}

export default logger
