import { useCallback } from 'react'

import { Session } from '@ory/client'
import useTranslation from 'next-translate/useTranslation'
import getConfig from 'next/config'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'

import { AuthCallbackUrl, FB_MOBILE_AUTH_FLOW_COOKIE, LUMOSITY_USER_COOKIE } from '~/constants'
import { ErrorEventTypes } from '~/events/eventTypes'
import useTrackEvents from '~/events/trackers/useTrackEvents'
import { cache, createDefaultCache } from '~/graphql/cache'
import { useUTMSessionStorage } from '~/hooks/useUtmSessionStorage'
import logger from '~/utils/logger'
import {
  OrySessionParseError,
  generateJwtFromOrySession,
  getBrowserCookieOpts,
  attemptOryLogout,
} from '~/utils/loginUtils'

const { publicRuntimeConfig } = getConfig()

export function useFBMobileAuthFlow() {
  const [cookies, setCookie] = useCookies([FB_MOBILE_AUTH_FLOW_COOKIE])
  const fbMobileAuthFlowValue = cookies[FB_MOBILE_AUTH_FLOW_COOKIE]

  const recordFBMobileAuthFlow = useCallback(() => {
    setCookie(FB_MOBILE_AUTH_FLOW_COOKIE, true, {
      path: '/',
      sameSite: true,
    })
  }, [setCookie])

  const isFBMobileAuthFlow = useCallback(() => {
    return !!fbMobileAuthFlowValue
  }, [fbMobileAuthFlowValue])

  return {
    recordFBMobileAuthFlow,
    isFBMobileAuthFlow,
  }
}

export function usePostLogin() {
  const router = useRouter()
  const [cookies, setCookie] = useCookies([LUMOSITY_USER_COOKIE, 'NEXT_LOCALE'])
  const searchParams = useSearchParams()
  const { lang } = useTranslation()

  const language = cookies['NEXT_LOCALE'] ?? lang
  const homepageLink = `/${language === 'en' ? '' : language}`

  const zendeskReturnB64Url = searchParams.get('zendesk_redirect_b64')
  const { setUtmParams } = useUTMSessionStorage()

  /**
   * createOryReturnToUrl goes into ory returnTo,
   * After OIDC is successful, this is the url ory will redirect to
   * this preserves query parameters.
   */
  const createOryReturnToUrl = useCallback(() => {
    const baseUrl = window.location.origin
    if (zendeskReturnB64Url) {
      return `${baseUrl}${AuthCallbackUrl}?zendesk_redirect_b64=${zendeskReturnB64Url}`
    } else {
      return `${baseUrl}${AuthCallbackUrl}`
    }
  }, [zendeskReturnB64Url])

  const processOrySession = useCallback(
    async ({ orySession, onError }: { orySession: Session; onError: (error: any) => void }) => {
      const jwtResult = generateJwtFromOrySession(orySession)
      if (jwtResult.isErr()) {
        const { error } = jwtResult
        if (error instanceof OrySessionParseError) {
          onError(error)
        } else {
          logger.error(error)
          onError(null)
        }
        return
      }

      const jwt = jwtResult.value

      setCookie(LUMOSITY_USER_COOKIE, jwt, getBrowserCookieOpts())

      setUtmParams(null)

      if (zendeskReturnB64Url) {
        // decode uri to get base64 url
        // convert base64 to original url and redirect
        const zendeskReturnUrl = Buffer.from(decodeURIComponent(zendeskReturnB64Url), 'base64').toString()
        logger.log('zendeskReturnUrl', zendeskReturnUrl)
        window.location.href = zendeskReturnUrl
      } else {
        await router.push(homepageLink)
      }
    },
    [homepageLink, router, setCookie, setUtmParams, zendeskReturnB64Url],
  )

  return {
    processOrySession,
    createOryReturnToUrl,
  }
}

export function useLogout() {
  const [, , removeCookie] = useCookies()
  const { trackSignOut, trackErrorEvent } = useTrackEvents()

  const processOryLogout = useCallback(async () => {
    await attemptOryLogout((error) => {
      trackErrorEvent({
        error_type: ErrorEventTypes.LogOutFail,
        error_location: 'Error::processOryLogout::createBrowserLogoutFlow',
        error_message: '',
        error_details: error,
      })
    })
    trackSignOut()
    cache.restore(createDefaultCache().extract())
    removeCookie(LUMOSITY_USER_COOKIE, getBrowserCookieOpts())
    //remove Flagship http-only cookie on logout, so we don't run into an infinite loop with Flagship user continuity
    const flagshipLogoutUrl = `${publicRuntimeConfig.authMiddleware.endpoint}/flagship/logout`
    try {
      await fetch(flagshipLogoutUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        cache: 'no-store',
        body: '{}',
      })
    } catch (error) {
      trackErrorEvent({
        error_type: ErrorEventTypes.LogOutFail,
        error_location: 'Error::processOryLogout::FlagshipLogout',
        error_message: '',
        error_details: error,
      })
      logger.log('Failed to remove flagship cookie', error)
    }
    // ory will automatically redirect to landing page
  }, [removeCookie, trackErrorEvent, trackSignOut])

  return {
    processOryLogout,
  }
}
