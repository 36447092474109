import React from 'react'

import { useReactiveVar } from '@apollo/client'
import getConfig from 'next/config'

import { IPostLumosEventRequest, useLumosEventApi } from '~/events/clients/useLumosEventApi'
import { EventsContext, EventsContextInterface } from '~/events/EventsContext'
import { getEventAnonymousId } from '~/events/utils/eventAnonymousId'
import { getEventDeviceId } from '~/events/utils/eventDeviceId'
import { getEventSessionId } from '~/events/utils/eventSessionId'
import { currentUserVar } from '~/graphql/reactive-vars/userVar'
import { useUserAgentInfo } from '~/hooks/useUserAgentInfo'
import dayjs from '~/libs/dayjs'
import logger from '~/utils/logger'

const { publicRuntimeConfig } = getConfig()

type LumosEventSendFunction = EventsContextInterface['clients']['lumosEventApi']['track']

const EventsProvider: React.FunctionComponent = ({ children }) => {
  const { uaResults } = useUserAgentInfo()

  const [currentScreen, setCurrentScreen] = React.useState<string>('')
  const currentUser = useReactiveVar(currentUserVar)

  const { postLumosEvent } = useLumosEventApi()

  const sendLumosEvents: LumosEventSendFunction = (eventName, payload) => {
    const timeStamp = Date.now()

    const apiPayload: IPostLumosEventRequest = {
      name: eventName,
      user_id: currentUser?.id,
      local_timestamp: timeStamp,
      // sent_timestamp is the time when the event was sent to the server
      // for now we are using the same timestamp as the event_timestamp
      // Api needs this information because currently its required mostly for mobile apps,
      // in future, if we adds feature of queue or offline support in web
      // then we need to update this timestamp & sent_timestamp logic accordingly
      sent_timestamp: timeStamp,
      channel: 'web',
      payload: payload,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timezone_offset: dayjs().format('Z'),
      locale: navigator?.language || 'en-US',
      device_id: getEventDeviceId(),
      anonymous_id: getEventAnonymousId(currentUser),
      session_id: getEventSessionId(currentUser),
      app: {
        name: 'lumosity2_web',
        platform: 'web',
        version: publicRuntimeConfig.appVersion,
      },
      device: {
        model: uaResults.device.model || '',
        manufacturer: uaResults.device.vendor || '',
      },
      os: {
        name: uaResults.os.name || '',
        version: uaResults.os.version || '',
      },
      browser: {
        name: uaResults.browser.name,
        version: uaResults.browser.version,
        user_agent: uaResults.ua,
        screen_height: window.innerWidth,
        screen_width: window.innerHeight,
        device_pixel_ratio: window.devicePixelRatio,
      },
      screen: {
        height: window.screen.height,
        width: window.screen.height,
      },
    }
    logger.log('EventsProvider::EventApi', apiPayload)
    postLumosEvent(apiPayload)
  }

  /**
   * Showcasing how multiple vendor can send events
   * const sendAmplitudeEvent = () =>{
   *   // example of how amplitude events can get sent
   * }
   * const sendGoogleTagManagerEvent = () =>{
   *   // example of how amplitude events can get sent
   * }
   */

  return (
    <EventsContext.Provider
      value={{
        clients: {
          lumosEventApi: {
            track: sendLumosEvents,
          },
          /**
           *  example of how same clients interface can utilize multiple vendors
           *  amplitude:{
           *    sendEvent: sendAmplitudeEvent
           *  }
           */
        },
        currentScreen,
        setCurrentScreen,
      }}
    >
      {children}
    </EventsContext.Provider>
  )
}

export default EventsProvider
