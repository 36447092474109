import {
  BodyText1,
  BodyText2,
  BodyText2SemiBold,
  MicroText2,
  Subheading1,
  Subheading2,
} from '@lumoslabs/lumosity-storybook'
import Trans from 'next-translate/Trans'
import Link from 'next/link'
import styled, { css } from 'styled-components'

import { UserHelpCenterUrl } from '~/constants'
import CloseX from '~/images/icons/SystemOutlined/Close.svg'

export const GameCardAura = styled.div`
  padding: 8px;
  position: relative;
`

export const AreaLabel = styled(MicroText2)`
  color: ${(props) => props.theme.colors.neutral[200]};
`

export const CardShadowRadius = styled.div`
  background: ${(props) => props.theme.colors.background?.card};
  box-shadow: ${(props) => props.theme.effects.boxShadow.outset[2]};
  border-radius: 16px;
`

export const CardNoShadow = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral[100]};
  background: ${(props) => props.theme.colors.background?.card};
  border-radius: 16px;
  padding: 24px;
`

export const ExitButtonContainer = styled.div`
  padding-left: 40px;
  > a {
    margin-top: 128px;
    min-height: 0;
    min-width: 0;
    box-sizing: content-box;
  }
  ${({ theme }) => theme.mediaQuery.maxWidth.tablet} {
    a {
      > span:first-child {
        padding: 0;
        > svg {
          padding: 0;
          text-align: center;
        }
      }
      > span:nth-child(2) {
        display: none;
      }
    }
  }
`

export const UnlockBadge = styled.div`
  width: 64px;
`

export const Error = () => {
  return (
    <ErrorText as='h1'>
      <MiscReloadError />
    </ErrorText>
  )
}

const ErrorText = styled(BodyText1)`
  display: block;
  text-align: center;
`

export const MiscReloadError = () => {
  return (
    <Trans
      i18nKey={'common.errorMessages.miscReloadError'}
      components={{
        p: <BodyText1 as='p' />,
        b: <strong />,
        HelpLink: <ErrorLink href={UserHelpCenterUrl} prefetch={false} />,
        HomeLink: <ErrorLink href={'/'} prefetch={false} />,
      }}
      /* Include fallback text error about being offline in English as translations don't work offline */
      fallback="Looks like you're offline! Please check your internet connection and try again."
    />
  )
}

const ErrorLink = styled(Link)`
  text-decoration: underline;
`

export const SettingsCardHeaders = styled(Subheading2)`
  color: ${({ theme }) => theme.colorTokens.text['text-default']};
`

export const SettingsCardBodyHeader = styled(BodyText2SemiBold)`
  color: ${({ theme }) => theme.colorTokens.text['text-default']};
`

export const SettingsCardBody = styled(BodyText2)`
  color: ${({ theme }) => theme.colorTokens.text['text-subdued']};
`

const actionLinkStyles = css`
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  box-shadow: none;
  background: none;
  text-decoration: underline;
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colorTokens.text['text-interactive']};
  :disabled {
    color: ${({ theme }) => theme.colorTokens.text['text-disabled']};
    cursor: not-allowed;
    pointer-events: none;
  }
  :hover {
    font-weight: 400;
  }
`

export const ActionButton = styled.button`
  ${actionLinkStyles}
`

export const ActionLink = styled(Link)`
  ${actionLinkStyles}
`

export const CenteredFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
`

export const SettingsInfoCard = styled(CardNoShadow)`
  margin: 0 20px 40px;
`

export const CardLabel = styled(Subheading1)`
  margin: 0 20px 20px;
`

export const CloseNotificationButton = styled(CloseX)`
  color: ${({ theme }) => theme.colorTokens.icon['icon-subdued']};
  cursor: pointer;
  font-size: 24px;
  min-width: 24px;
`

export const LandingPageGridSection = styled.section`
  padding: 8%;
  grid-column: 1 / -1;
`
