import getConfig from 'next/config'

import logger from '~/utils/logger'

const { publicRuntimeConfig } = getConfig()

export interface IPostLumosEventRequest {
  name: string
  user_id?: number
  local_timestamp: number
  sent_timestamp: number
  channel: 'web'
  payload: { [key: string]: any }
  timezone: string
  timezone_offset: string
  locale: string
  anonymous_id: string
  session_id: string
  device_id: string
  os?: {
    name?: string
    version?: string
  }
  app?: {
    name?: string
    version?: string
    platform?: string
  }
  device?: {
    model?: string
    manufacturer?: string
  }
  browser?: {
    name?: string
    version?: string
    user_agent?: string
    screen_height?: number
    screen_width?: number
    device_pixel_ratio?: number
  }
  screen?: {
    density?: number
    height?: number
    width?: number
  }
}

export const useLumosEventApi = () => {
  const postLumosEvent = (payload: IPostLumosEventRequest) => {
    fetch(`${publicRuntimeConfig.lumosEvents.endpoint}/events`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }).catch(logger.error)
    /** TODO LUM-2015: for catch, have a retry logic for 5XX errors,
     * and log for other kind of errors */
  }
  return {
    postLumosEvent,
  }
}
